import { render, staticRenderFns } from "./FirmwareItem.vue?vue&type=template&id=3a3666a3&scoped=true"
import script from "./FirmwareItem.vue?vue&type=script&lang=js"
export * from "./FirmwareItem.vue?vue&type=script&lang=js"
import style0 from "./FirmwareItem.vue?vue&type=style&index=0&id=3a3666a3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a3666a3",
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/firmware.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Ffirmware%2FFirmwareItem.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports