<i18n locale="th" lang="yaml" >
firmware_set.models.title : "ใช้ใน Drone Model"
firmware_set.firmwares.title : "รายการ Firmware"
firmware_set.usage_stat.title : "สถิติการใช้งาน Firmware"

firmware_set.remark.title : "หมายเหตุ Firmware Set"

firmware_set.firmwares.empty : "ไม่พบรายการ Firmware"
firmware_set.models.empty : "ไม่มีการใช้งานใน Drone Model"

firmware_set.delete.button : "ลบ Firmware Set นี้"
firmware_set.update.button : "แก้ไข Firmware Set นี้"

firmware_set.firmware.create.button : "Firmware"
firmware_set.models.draft : "(Draft)"
</i18n>

<template>
	<div class="firmware-set-card">
		<div v-if="canManage" class="firmware-set-actions">
			<a-button v-if="canDelete" icon="delete" type="link" class="text-error"
			 :title="$t('firmware_set.delete.button')"
				@click="clickDelete"/>
			<a-button icon="edit" type="link" :title="$t('firmware_set.update.button')"
				@click="clickUpdate"/>
		</div>
		<div class="firmware-set-header">
			<div class="title">
				{{firmwareSet.name}}
			</div>
			<div v-if="$notEmpty(firmwareSet.remark)" v-trim class="remark multiline" >
				{{firmwareSet.remark}}
			</div>
		</div>
		<!-- <div class="models-row">
			<div class="section-title">
				{{$t('firmware_set.models.title')}}
			</div>
			<MyCardList
				:vertical="true"
				:empty="!$notEmpty(firmwareSet.models)">
				<div slot="empty" class="empty">
					{{$t('firmware_set.models.empty')}}
				</div>

				<div v-for="model of firmwareSet.models" :key="model.id" class="item">
					<my-router-link v-if="model.status != 'draft'" name="drone_model/view" :param-value="model.id" auth-action="read" auth-module="droneModel">
						{{model.name}}
					</my-router-link>
					<span v-else>
						{{model.name}} {{$t('firmware_set.models.draft')}}
					</span>
				</div>
			</MyCardList>
		</div> -->

		<div class="firmwares-row">
			<div class="firmware-set-firmwares firmwares-column">
				<div v-if="canManage" class="firmwares-actions">
					<a-button icon="plus" size="small" class="text-primary" @click="clickCreateFirmware">
						{{$t('firmware_set.firmware.create.button')}}
					</a-button>
				</div>
				<div class='section-title'>
					{{$t('firmware_set.firmwares.title')}}
				</div>
				<MyCardList
					:vertical="true"
					class="mycard-list-line mycard-list-striped"
					:empty="!$notEmpty(firmwareSet.firmwares)">
					<div slot="empty" class="empty">
						{{$t('firmware_set.firmwares.empty')}}
					</div>

					<FirmwareItem
						v-for="firmware of firmwareSet.firmwares"
						:key="firmware.id"
						:firmware="firmware"
						:can-manage="canManage"
						@firmware="handleFirmwareAction"/>


				</MyCardList>
			</div>
			<div v-if="canViewFirmwareStat" class="firmware-set-usage-stat firmwares-column">
				<div class='section-title'>
					{{$t('firmware_set.usage_stat.title')}}
				</div>
				<DroneFirmwareSetChart
					class="center-block"
					:firmware-set="firmwareSet"
					:chart-data="firmwareStat"/>
			</div>
		</div>
	</div>
</template>

<script>
import MyCardList from "@components/common/MyCardList.vue"
import FirmwareItem from "./FirmwareItem.vue"
import DroneFirmwareSetChart from "@components/chart/DroneFirmwareSetChart.vue"
import {mapState} from "vuex"
export default {
	components : {
		MyCardList , FirmwareItem, DroneFirmwareSetChart
	} ,
	props : {
		firmwareSet : {
			type : null,
			default : () => []
		} ,
		stat : {
			type : null,
			default : () => []
		} ,
		canManage : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		...mapState('auth',['currentCompany']) ,
		firmwareStat() {
			if (this.firmwareSet.id in this.stat) {
				return this.stat[this.firmwareSet.id]
			} else {
				return []
			}
		} ,
		canDelete() {
			return this.canManage && !(this.firmwareSet.id in this.stat)
		} ,
		canViewFirmwareStat() {
			const companyType = this.currentCompany.company_type
			return this.$isManagement(companyType) || this.$isFlagship(companyType)
		}
	} ,
	methods : {
		clickUpdate() {
			this.$emit('firmware_set',{
				action : 'update',
				firmware_set : {...this.firmwareSet}
			})
		} ,
		clickDelete() {
			this.$emit('firmware_set',{
				action : 'delete',
				firmware_set : {...this.firmwareSet}
			})
		} ,
		clickCreateFirmware() {
			this.$emit('firmware_set',{
				action : 'create_firmware' ,
				firmware_set : {...this.firmwareSet}
			})
		} ,
		handleFirmwareAction(payload) {
			payload.firmware_set = {...this.firmwareSet}
			this.$emit('firmware',payload)
		} ,

	}
}
</script>

<style lang="less" scoped>
.firmware-set-card {
	background-color : @white;
	box-shadow: @shadow-2;
	width : 100%;
	margin-bottom : 16px;
	padding : 16px 24px;
	text-align : left;
	position: relative;

	.empty {
		color : @error-color;
		padding : 8px;
		background-color : transparent;
	}
}
.firmware-set-actions {
	position : absolute;
	right : 24px;
	top : 16px;
}
.firmware-set-header {
	margin-bottom : 12px;
	.title {
		font-family: @font-family-title;
		font-size : 1.5em;
		color : @primary-color;
	}
	.remark {
		color : @text-muted;
		font-size : 0.95em;
		margin-top : 4px;
		padding-bottom : 8px;
	}
}
.section-title {
	font-family: @font-family-title;
	font-size : 1.2em;
	color : @primary-color;
	text-decoration: underline;
	margin-bottom : 8px;
	.mobile &,.tablet  & {
		font-size : 1em;
	}
}
.models-row {
	margin-bottom : 32px;
}


.firmwares-row {
	display : flex;
	flex-direction: row;
	flex-wrap : wrap;
	align-items: stretch;
	justify-content: stretch;
	.firmwares-column {
		position : relative;
		flex-grow: 1;
		flex-basis: 0;
		.firmwares-actions {
			position : absolute;
			top : 4px;
			right : 4px;
		}
	}
	.firmware-set-usage-stat {
		margin-left : 16px;
		padding-left : 16px;
		border-left : 1px solid @border-color-base;
	}

	.mobile & {
		flex-direction : column;
		.firmware-set-usage-stat {
			border-left : none;
			padding-left : 0;
			margin-left : 0;
			margin-top : 32px;
		}
	}
}
</style>
