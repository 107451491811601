<i18n locale="th" lang="yaml" src="@i18n/drone/firmware.th.yaml">
</i18n>

<template>
	<div class="firmware-item">
		<div v-if="canManage" class="actions">
			<a-button v-if="canDelete" icon="delete" type="link" class="text-error"
				 @click="clickDelete"/>
			<a-button icon="edit" type="link" @click="clickUpdate" />
		</div>
		<div class="title">{{firmware.name}}</div>
		<div v-if="$notEmpty(firmware.remark)" v-trim class="multiline remark">
			{{firmware.remark}}
		</div>
		<div class="row firmware-item-detail text-right">
			<label>{{$t('firmware.field.released_date')}}</label>
			{{firmware.released_date | date}}
		</div>

	</div>
</template>

<script>
export default {
	props : {
		firmware : {
			type : null,
			default : () => []
		} ,
		canManage : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		canDelete() {
			return this.firmware.num_usage <= 0
		}
	} ,
	methods : {
		clickUpdate() {
			this.$emit('firmware',{
				action : 'update',
				firmware : {...this.firmware}
			})
		} ,
		clickDelete() {
			this.$emit('firmware',{
				action : 'delete' ,
				firmware : {...this.firmware}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.firmware-item {
	position : relative;
	width : 100%;
	padding : 8px 16px;
	font-size : 0.95em;
	.actions {
		position : absolute;
		top : 8px;
		right : 18px;
	}
	.title {
		font-family: @font-family-title;
		font-size : 1.2em;
		color : @secondary-color;
		margin-bottom : 4px;
	}
	.remark {
		font-size : 0.9em;
		color : @text-muted;
		margin-bottom : 8px;
	}
}
</style>
