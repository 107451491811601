var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.cModalTitle,"ok-text":_vm.cModalOkLabel,"width":350,"confirm-loading":_vm.loading,"cancel-button-props":{ props: { disabled: _vm.loading } },"mask-closable":!_vm.loading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.formObj}},[_c('a-form-item',{attrs:{"label":_vm.$t('firmware.field.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','firmware.field.name') },
					] },
				]),expression:"[\n\t\t\t\t\t'name' ,\n\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','firmware.field.name') },\n\t\t\t\t\t] },\n\t\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('firmware.field.name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('firmware.field.released_date'),"extra":_vm.$t('firmware.field.released_date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['released_date',
						{
							rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','firmware.field.released_date') },
							] ,
						}]),expression:"['released_date',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','firmware.field.released_date') },\n\t\t\t\t\t\t\t] ,\n\t\t\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('firmware.field.remark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3,maxRows: 5 },"placeholder":_vm.$t('firmware.field.remark.placeholder')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }